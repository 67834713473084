import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AxiosApiClient, CheckinVisitorDto, TenantDataApi } from "@smallstack/axios-api-client";
import { CHECKIN_ACTION_NAMES, CheckinApp, CheckinAppFileUpload } from "@smallstack/checkin-shared";
import { F_CHECKIN, T_CHECKIN_VISITOR } from "@smallstack/typesystem";
import { dataUrlToBlob } from "@smallstack/legacy-utils";

@Injectable({ providedIn: "root" })
export class CheckinService {
  constructor(
    protected axiosApiClient: AxiosApiClient,
    private http: HttpClient
  ) {}

  public async createVisitor(
    visitor: CheckinVisitorDto,
    meta: { appId?: string; formId?: string; createEvent?: string }
  ): Promise<string> {
    // extract files
    const files: { [name: string]: string } = {};
    for (const field in visitor.fields) {
      if (typeof visitor.fields[field] === "string" && visitor.fields[field].startsWith("data:")) {
        files[field] = visitor.fields[field];
        delete visitor.fields[field];
      }
    }

    // set delete on flag?
    if (meta?.createEvent && typeof visitor.deleteOnPreference === "number")
      visitor.deleteOn = Date.now() + visitor.deleteOnPreference;

    // create visitor
    const createdVisitor: CheckinVisitorDto = (
      await this.axiosApiClient
        .get(TenantDataApi)
        .createData({ anyBody: visitor, feature: F_CHECKIN, type: T_CHECKIN_VISITOR })
    ).data as any;

    // upload files
    for (const filePropertyName in files) {
      if (files[filePropertyName]) {
        const response: string = await this.axiosApiClient
          .get(TenantDataApi)
          .dispatchDataAction({
            actionName: "uploadfile",
            anyBody: { propertyName: filePropertyName, fileName: filePropertyName } as CheckinAppFileUpload,
            id: createdVisitor.id,
            feature: F_CHECKIN,
            type: T_CHECKIN_VISITOR
          })
          .then((res) => res.data);
        if (response) {
          const blob = dataUrlToBlob(files[filePropertyName]);
          const req = new HttpRequest("PUT", response, blob, {
            headers: new HttpHeaders({
              "Content-Type": blob.type,
              "Content-Disposition": `attachment; filename="${filePropertyName}"`
            }),
            reportProgress: true
          });
          await this.http.request(req).toPromise();
        } else throw new Error("Fileupload ist fehlgeschlagen: Der Server lieferte keine Uploadurl zurück");
      }
    }

    // create visitor event
    if (meta?.createEvent) await this.createVisitEvent(createdVisitor.id, meta.createEvent, meta);
    return createdVisitor.id;
  }

  public async createVisitEvent(
    visitorId: string,
    type: string,
    meta: { appId?: string; formId?: string } = {}
  ): Promise<void> {
    await this.axiosApiClient.get(TenantDataApi).dispatchDataAction({
      actionName: CHECKIN_ACTION_NAMES.EVENT,
      anyBody: { ...meta, eventName: type },
      id: visitorId,
      feature: F_CHECKIN,
      type: T_CHECKIN_VISITOR
    });
  }

  public getDisplayName(visitor: CheckinVisitorDto): string {
    if (visitor.fields) {
      let str = "";

      for (const fieldName in visitor.fields) {
        if (visitor.fields[fieldName])
          if (fieldName.toLowerCase().includes("name") || fieldName.toLowerCase().includes("mail")) {
            if (str !== "") str += " ";
            str += visitor.fields[fieldName];
          }
      }
      if (str !== "") return str;
    }
    return visitor.id;
  }

  public getAllEvents(apps: CheckinApp[]): { label: string; name: string }[] {
    const events: { label: string; name: string }[] = [];
    apps.forEach((app) => {
      app.events?.forEach((event) => {
        events.push({ label: event.label, name: event.name });
      });
    });
    return events;
  }
}
